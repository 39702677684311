// frontend/src/pages/forums/ThreadPage.tsx

import { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PageLayout from "../../components/PageLayout";
import AuthContext from "../../context/AuthContext";
import { JSX } from "react";

type Thread = {
  id: number;
  title: string;
  content: string;
  username: string;
  created_at: string;
};

type Reply = {
  id: number;
  content: string;
  username: string;
  created_at: string;
  parent_id: number | null;
  children?: Reply[];
};

const ThreadPage = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  const [thread, setThread] = useState<Thread | null>(null);
  const [topLevelReplies, setTopLevelReplies] = useState<Reply[]>([]);
  const [childReplies, setChildReplies] = useState<Reply[]>([]);
  const [replyInputs, setReplyInputs] = useState<Record<string, string>>({});
  const [editingReplyId, setEditingReplyId] = useState<number | null>(null);
  const [editedContent, setEditedContent] = useState("");

  const [page, setPage] = useState(1);
  const [totalReplies, setTotalReplies] = useState(0);
  const limit = 10;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!id) return;

    const fetchData = async () => {
      try {
        const threadRes = await fetch(`/api/forums/threads/${id}`);
        if (!threadRes.ok) throw new Error("Thread not found");
        const threadData = await threadRes.json();

        const repliesRes = await fetch(`/api/forums/threads/${id}/replies?page=${page}&limit=${limit}`);
        const repliesData = await repliesRes.json();

        setThread(threadData.thread);
        setTopLevelReplies(repliesData.topLevelReplies || []);
        setChildReplies(repliesData.childReplies || []);
        setTotalReplies(repliesData.total || 0);
        setLoading(false);
      } catch (err: any) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [id, page]);

  const buildReplyTree = (): Reply[] => {
    const map = new Map<number, Reply & { children: Reply[] }>();
    for (const reply of [...topLevelReplies, ...childReplies]) {
      (reply as any).children = [];
      map.set(reply.id, reply as any);
    }

    for (const reply of childReplies) {
      if (reply.parent_id) {
        const parent = map.get(reply.parent_id);
        parent?.children.push(reply as any);
      }
    }

    return topLevelReplies.map(r => map.get(r.id)!);
  };

  const handleSubmit = async (parentId: number | null = null) => {
    const inputKey = parentId ?? "root";
    if (!id || !replyInputs[inputKey]?.trim()) return;

    try {
      const res = await fetch(`/api/forums/threads/${id}/replies`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
        body: JSON.stringify({
          content: replyInputs[inputKey],
          parent_id: parentId,
        }),
      });

      if (res.ok) {
        const repliesRes = await fetch(`/api/forums/threads/${id}/replies?page=${page}&limit=${limit}`);
        const repliesData = await repliesRes.json();

        setTopLevelReplies(repliesData.topLevelReplies || []);
        setChildReplies(repliesData.childReplies || []);
        setTotalReplies(repliesData.total || 0);

        setReplyInputs((prev) => {
          const copy = { ...prev };
          delete copy[inputKey];
          return copy;
        });
      } else {
        alert("Failed to post reply.");
      }
    } catch {
      alert("Failed to post reply.");
    }
  };

  const handleEdit = async (replyId: number) => {
    const res = await fetch(`/api/forums/replies/${replyId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      },
      body: JSON.stringify({ content: editedContent }),
    });

    if (res.ok) {
      setTopLevelReplies((prev) =>
        prev.map((r) => (r.id === replyId ? { ...r, content: editedContent } : r))
      );
      setChildReplies((prev) =>
        prev.map((r) => (r.id === replyId ? { ...r, content: editedContent } : r))
      );
      setEditingReplyId(null);
    } else {
      alert("Failed to update reply.");
    }
  };

  const handleDelete = async (replyId: number) => {
    if (!confirm("Are you sure you want to delete this reply?")) return;

    const res = await fetch(`/api/forums/replies/${replyId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    });

    if (res.ok) {
      setTopLevelReplies((prev) => prev.filter((r) => r.id !== replyId));
      setChildReplies((prev) => prev.filter((r) => r.id !== replyId));
      setTotalReplies((prev) => prev - 1);
    } else {
      alert("Failed to delete reply.");
    }
  };

  const renderReplies = (replyList: Reply[], depth = 0): JSX.Element[] => {
    return replyList.map((reply) => {
      const isAuthor = user?.username === reply.username;
      const isEditing = editingReplyId === reply.id;
      const children = reply.children || [];
  
      // Limit nesting to 5 levels max
      const effectiveDepth = Math.min(depth, 5);
      const paddingLeft = 12 + effectiveDepth * 16;
  
      return (
        <div key={reply.id} className="relative pl-4">
          <div
            className="relative"
            style={{ marginLeft: `${paddingLeft}px` }}
          >
            {depth > 0 && (
              <div className="absolute left-[-16px] top-0 bottom-0 w-px bg-gray-700" />
            )}
  
            <div className="pb-2">
              <p className="text-sm text-gray-400 mb-1">
                <span className="text-white font-medium">{reply.username}</span> ·{" "}
                {new Date(reply.created_at).toLocaleString()}
              </p>
  
              {isEditing ? (
                <>
                  <textarea
                    className="w-full bg-[#121217] text-white border border-gray-700 rounded p-2 text-sm"
                    rows={4}
                    value={editedContent}
                    onChange={(e) => setEditedContent(e.target.value)}
                  />
                  <div className="flex gap-2 mt-2">
                    <button
                      onClick={() => handleEdit(reply.id)}
                      className="px-3 py-1 bg-green-600 text-white text-sm rounded hover:bg-green-500 transition"
                    >
                      Save
                    </button>
                    <button
                      onClick={() => setEditingReplyId(null)}
                      className="px-3 py-1 bg-gray-700 text-white text-sm rounded hover:bg-gray-600 transition"
                    >
                      Cancel
                    </button>
                  </div>
                </>
              ) : (
                <p className="text-gray-300 whitespace-pre-wrap">{reply.content}</p>
              )}
  
              <div className="flex gap-4 text-sm mt-2">
                {user && !isEditing && (
                  <button
                    onClick={() =>
                      setReplyInputs((prev) => ({
                        ...prev,
                        [reply.id]: prev[reply.id] ?? "",
                      }))
                    }
                    className="text-blue-400 hover:underline"
                  >
                    Reply
                  </button>
                )}
                {isAuthor && !isEditing && (
                  <>
                    <button
                      onClick={() => {
                        setEditingReplyId(reply.id);
                        setEditedContent(reply.content);
                      }}
                      className="text-yellow-400 hover:underline"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDelete(reply.id)}
                      className="text-red-500 hover:underline"
                    >
                      Delete
                    </button>
                  </>
                )}
              </div>
  
              {replyInputs[reply.id] !== undefined && (
                <div className="mt-3">
                  <textarea
                    rows={4}
                    className="w-full bg-[#1e1e22] text-white border border-gray-700 rounded p-2 text-sm mb-2"
                    placeholder="Write your reply..."
                    value={replyInputs[reply.id]}
                    onChange={(e) =>
                      setReplyInputs((prev) => ({
                        ...prev,
                        [reply.id]: e.target.value,
                      }))
                    }
                  />
                  <div className="flex gap-2">
                    <button
                      onClick={() => handleSubmit(reply.id)}
                      className="px-4 py-2 bg-purple-600 text-white rounded hover:bg-purple-500 transition"
                    >
                      Post Reply
                    </button>
                    <button
                      onClick={() =>
                        setReplyInputs((prev) => {
                          const copy = { ...prev };
                          delete copy[reply.id];
                          return copy;
                        })
                      }
                      className="text-sm text-gray-400 hover:underline"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </div>
  
            {children.length > 0 && renderReplies(children, depth + 1)}
          </div>
        </div>
      );
    });
  };  
  return (
    <PageLayout fullWidth>
      <div className="w-full overflow-x-auto py-16 px-4">
        {loading ? (
          <p className="text-center text-gray-400">Loading thread...</p>
        ) : error ? (
          <div className="text-center text-red-400">
            <p>❌ {error}</p>
            <button
              onClick={() => navigate("/forums")}
              className="mt-4 bg-purple-600 text-white px-4 py-2 rounded hover:bg-purple-500 transition"
            >
              Back to Forums
            </button>
          </div>
        ) : thread ? (
          <>
            <h1 className="text-3xl font-bold text-purple-400 mb-4">{thread.title}</h1>
            <p className="text-sm text-gray-500 mb-6">
              Posted by <span className="text-white">{thread.username}</span> on{" "}
              {new Date(thread.created_at).toLocaleString()}
            </p>
            <div className="bg-[#1e1e22] text-gray-300 p-6 rounded-lg border border-gray-700 whitespace-pre-wrap mb-10 w-full overflow-x-auto">
              {thread.content}
            </div>

            <div className="mb-10">{renderReplies(buildReplyTree())}</div>

            {totalReplies > limit && (
              <div className="flex justify-center gap-4 mb-10">
                <button
                  onClick={() => setPage((p) => Math.max(p - 1, 1))}
                  disabled={page === 1}
                  className="px-4 py-2 bg-gray-800 text-white rounded hover:bg-gray-700 disabled:opacity-40"
                >
                  Previous
                </button>
                <span className="text-gray-400 pt-2">
                  Page {page} of {Math.ceil(totalReplies / limit)}
                </span>
                <button
                  onClick={() => setPage((p) => Math.min(p + 1, Math.ceil(totalReplies / limit)))}
                  disabled={page === Math.ceil(totalReplies / limit)}
                  className="px-4 py-2 bg-gray-800 text-white rounded hover:bg-gray-700 disabled:opacity-40"
                >
                  Next
                </button>
              </div>
            )}

            {user ? (
              <>
                <textarea
                  value={replyInputs["root"] || ""}
                  onChange={(e) =>
                    setReplyInputs((prev) => ({
                      ...prev,
                      root: e.target.value,
                    }))
                  }
                  required
                  rows={5}
                  className="w-full rounded bg-[#1e1e22] border border-gray-700 p-3 text-white mb-4"
                  placeholder="Write your reply..."
                />
                <button
                  onClick={() => handleSubmit(null)}
                  className="bg-purple-600 text-white px-6 py-2 rounded hover:bg-purple-500 transition"
                >
                  Post Reply
                </button>
              </>
            ) : (
              <p className="text-center text-gray-500">
                ⚠ You must be logged in to reply to this thread.
              </p>
            )}
          </>
        ) : null}
      </div>
    </PageLayout>
  );
};

export default ThreadPage;
